export enum DealPropertyWhyMainResidenceDiffers {
    STUDENT_ABROAD = "Auslandsstudent/in",
    FOREIGN_CARER = "ausländische Pflegekraft",
    DIPLOMATIC_REPRESENTATION = "Diplomatische Vertretung",
    OTHER = "Sonstige schlüssige Begründung",
}

export enum DealPropertyTinAvailable {
    YES = "Ja",
    NO_TIN_AVAILABLE = "Nein, das Land vergibt keine TIN",
    NO_TIN_RECEIVED = "Nein, ich habe den TIN beantragt, aber noch nicht erhalten",
    NO_OTHER = "Nein, sonstige Begründung",
}

export enum DealPropertyKestTaxStatus {
    RESIDENT = "Hiermit bestätige ich, dass ich einen Wohnsitz bzw. gewöhnlichen Aufenthalt in Österreich habe",
    SECOND_HOME = "Hiermit bestätige ich, dass ich einen Zweitwohnsitz in Österreich habe",
    NO_RESIDENCE = "Hiermit bestätige ich, dass ich keinen Wohnsitz bzw. gewöhnlichen Aufenthalt in Österreich",
}
