import { ClosedLostReasonSingleValues, DealNextStep, EmailTemplate } from "../models";

export const UTM_MEDIUM_IMMOSCOUT = "immoscout";
export const UTM_MEDIUM_COLDCALL = "Coldcall";

export const EmailTemplates: Readonly<Record<EmailTemplate, string>> = {
    [EmailTemplate.SURVEY_SUBMIT_TIPSTER_CUSTOMER]: "d-1102c4e20c224fdcad0dad29094988e9",
    [EmailTemplate.DEAL_FORM_SUBMIT_BY_TIPSTER]: "d-2ee8440c79894ffebea6fb3cfa330786",
    /* Survey Submits E-Mails */
    [EmailTemplate.SURVEY_SUBMIT_COMMERCIAL_OR_OTHER]: "d-a5fc9ede2aa341b589dc3777d835f35c",

    /* Deal Stage Change E-Mails */
    [EmailTemplate.SURVEY_SUBMIT_APPOINTMENT_BOOKED]: "d-a35bfa0571e542ee960505977e6be84d",
    [EmailTemplate.SURVEY_SUBMIT_HIGH_INTENT]: "d-d2a080efb7dd4735a4460a23c8591e43",
    [EmailTemplate.SURVEY_SUBMIT_HIGH_INTENT_NO_PHONE_NUMBER]: "d-1285f8adb1df439ab9ce0a20fba109d6",
    [EmailTemplate.AUTOMATED_EMAIL_CUSTOMER_REACHED_COLDCALL]: "d-477b4294e1fc4d4f9fafd69e0441cf47",
    [EmailTemplate.AUTOMATED_EMAIL_CUSTOMER_NOT_REACHED_COLDCALL]: "d-83976ccf4dfb4aea8a3c6bf3881ed76f",
    [EmailTemplate.AUTOMATED_EMAIL_CUSTOMER_NOT_REACHED_OTHER]: "d-cddda1b683a54cf4a44a92c786754f68",
    [EmailTemplate.AUTOMATED_EMAIL_CUSTOMER_NOT_REACHED_IMMOSCOUT]: "d-101bcf24f56249d798eb234413491bd2",
    [EmailTemplate.AUTOMATED_EMAIL_CUSTOMER_NOT_REACHED_REAL_ESTATE_AGENT]: "d-05b1dcf4448a47fda2919b0e6954cf5b",
    [EmailTemplate.WAITING_FOR_OFFER]: "d-fcc8f7c3d5654d69b8ea22aa4245c501",
    [EmailTemplate.CONTACT_DATA]: "d-d8c1eb5caa534428803c74d15acc5ebd",
    [EmailTemplate.MAGIC_LINK]: "d-21dbeb0721384ed9a0709321707d5947",
    [EmailTemplate.NEXT_STEPS_NOT_REACHED]: "d-385585f6776741bd894581ff5512801f",
    [EmailTemplate.DATE_PROPOSED]: "d-fb0fd183846b4819b0b82c8f841ff49d",
    [EmailTemplate.WAITING_FOR_SOMETHING_IN_GENERAL]: "d-61443bbac1844c87bc3969f9043ef980",
    [EmailTemplate.SIGHTSEEING]: "d-fc5d5e282c254d47b1d0c2a29a2e260e",
    [EmailTemplate.FOLLOW_UP]: "d-99c68d7bfe524a67b00240ab5902eccb",
    [EmailTemplate.ARCHIVE_COMPETITION]: "d-a12e19bcd7ca4608aaef7bf3e399bacc",

    [EmailTemplate.ARCHIVE_NOT_REACHED]: "d-c60b96e6aaf84c8d87fde64db3cf0655",
    [EmailTemplate.ARCHIVE_NO_ANSWER]: "d-c2a361940c874cf39cd94c00e29c604b",
    [EmailTemplate.ARCHIVE_WRONG_NUMBER]: "d-d4f87c3496114deb8a9a21b9de8012d2",

    [EmailTemplate.SECOND_EMAIL_WAITING_FOR_OFFER]: "d-56635b4d172e4dde9748142d157a6313",
    [EmailTemplate.SECOND_EMAIL_DATE_PROPOSED]: "d-75482f6897374a5c9564bca59c7d9e7c",
    [EmailTemplate.SECOND_EMAIL_WAITING_FOR_SOMETHING_IN_GENERAL]: "d-ea1b0a808e3e494caaf3088c8783f82e",
    [EmailTemplate.SECOND_EMAIL_SIGHTSEEING]: "d-3fb291dcb74947f8949bc956cd5da0e7",
    [EmailTemplate.SECOND_EMAIL_FOLLOW_UP]: "d-0b0eac4b0ade441593f6e1343bd6203e",

    [EmailTemplate.THIRD_EMAIL]: "d-6d5de3e541da4bd49be8cab4aba6dcce",
    [EmailTemplate.AUTOMATED_EMAIL_OFFER_COMPARISON]: "d-c7728e5889b5469c953f694ff568a929",
    [EmailTemplate.AUTOMATED_EMAIL_CONSULTATION_TAB_MEETING_COMPLETION]: "d-df5c8372b354404e8c3939463902f771",
    [EmailTemplate.AUTOMATED_EMAIL_MISSING_DOCUMENTS]: "d-5e3ba7574e76459693d6cf164650ef16",
    [EmailTemplate.AUTOMATED_EMAIL_UPLOAD_DOCUMENTS]: "d-78ff87db4fe143f4b547936063fcbef6",

    [EmailTemplate.FINLINE_SUBMISSION]: "d-a1e0e2bd17cc4ae9beb6133fee46fa00",
} as const;

export const EmailTemplateSubjects: Readonly<Record<string, string>> = {
    [EmailTemplates[EmailTemplate.SURVEY_SUBMIT_TIPSTER_CUSTOMER]]: "Survey Welcome: Tipster",
    [EmailTemplates[EmailTemplate.DEAL_FORM_SUBMIT_BY_TIPSTER]]: "Deal Submit: Tipster",
    [EmailTemplates[EmailTemplate.SURVEY_SUBMIT_COMMERCIAL_OR_OTHER]]: "Survey Welcome: Submit Other or Commercial use",
    [EmailTemplates[EmailTemplate.SURVEY_SUBMIT_APPOINTMENT_BOOKED]]: "Survey Welcome: booked appointment",
    [EmailTemplates[EmailTemplate.SURVEY_SUBMIT_HIGH_INTENT]]: "Survey Welcome: high/medium intent with phone number",
    [EmailTemplates[EmailTemplate.SURVEY_SUBMIT_HIGH_INTENT_NO_PHONE_NUMBER]]:
        "Survey Welcome: high/medium intent without phone number",
    [EmailTemplates[EmailTemplate.AUTOMATED_EMAIL_CUSTOMER_NOT_REACHED_COLDCALL]]:
        "Automated Email: Customer not reached COLDCALL",
    [EmailTemplates[EmailTemplate.AUTOMATED_EMAIL_CUSTOMER_REACHED_COLDCALL]]:
        "Automated Email: Customer reached COLDCALL",
    [EmailTemplates[EmailTemplate.AUTOMATED_EMAIL_CUSTOMER_NOT_REACHED_OTHER]]:
        "Automated Email: Customer not reached OTHER",
    [EmailTemplates[EmailTemplate.AUTOMATED_EMAIL_CUSTOMER_NOT_REACHED_IMMOSCOUT]]:
        "Automated Email: Customer not reached IMMOSCOUT",
    [EmailTemplates[EmailTemplate.AUTOMATED_EMAIL_CUSTOMER_NOT_REACHED_REAL_ESTATE_AGENT]]:
        "Automated Email: Customer not reached REAL_ESTATE_AGENT",
    [EmailTemplates[EmailTemplate.WAITING_FOR_OFFER]]: "Automated Email: Next steps WAITING_FOR_OFFER",
    [EmailTemplates[EmailTemplate.CONTACT_DATA]]: "Automated Email: Next steps CONTACT_DATA",
    [EmailTemplates[EmailTemplate.MAGIC_LINK]]: "Automated Email: Next steps MAGIC_LINK",
    [EmailTemplates[EmailTemplate.NEXT_STEPS_NOT_REACHED]]: "Automated Email: Next steps NEXT_STEPS_NOT_REACHED",
    [EmailTemplates[EmailTemplate.DATE_PROPOSED]]: "Automated Email: Next steps DATE_PROPOSED",
    [EmailTemplates[EmailTemplate.WAITING_FOR_SOMETHING_IN_GENERAL]]:
        "Automated Email: Next steps FOR_SOMETHING_IN_GENERAL",
    [EmailTemplates[EmailTemplate.SIGHTSEEING]]: "Automated Email: Next steps SIGHTSEEING",
    [EmailTemplates[EmailTemplate.FOLLOW_UP]]: "Automated Email: Next steps FOLLOW_UP",
    [EmailTemplates[EmailTemplate.ARCHIVE_COMPETITION]]: "Automated Email: Next steps ARCHIVE (Competition)",
    [EmailTemplates[EmailTemplate.ARCHIVE_NOT_REACHED]]: "Automated Email: Next steps ARCHIVE (Not Reached)",
    [EmailTemplates[EmailTemplate.ARCHIVE_NO_ANSWER]]: "Automated Email: Next steps ARCHIVE (No Answer)",
    [EmailTemplates[EmailTemplate.ARCHIVE_WRONG_NUMBER]]: "Automated Email: Next steps ARCHIVE (Wrong number)",
    [EmailTemplates[EmailTemplate.SECOND_EMAIL_WAITING_FOR_OFFER]]:
        "Automated Email: Next steps SECOND_EMAIL_WAITING_FOR_OFFER",
    [EmailTemplates[EmailTemplate.SECOND_EMAIL_DATE_PROPOSED]]:
        "Automated Email: Next steps SECOND_EMAIL_DATE_PROPOSED",
    [EmailTemplates[EmailTemplate.SECOND_EMAIL_WAITING_FOR_SOMETHING_IN_GENERAL]]:
        "Automated Email: Next steps ECOND_EMAIL_WAITING_FOR_SOMETHING_IN_GENERAL",
    [EmailTemplates[EmailTemplate.SECOND_EMAIL_SIGHTSEEING]]: "Automated Email: Next steps SECOND_EMAIL_SIGHTSEEING",
    [EmailTemplates[EmailTemplate.SECOND_EMAIL_FOLLOW_UP]]: "Automated Email: Next steps SECOND_EMAIL_FOLLOW_UP",

    [EmailTemplates[EmailTemplate.THIRD_EMAIL]]: "Automated Email: Next steps THIRD_EMAIL",
    [EmailTemplates[EmailTemplate.AUTOMATED_EMAIL_OFFER_COMPARISON]]: "miracl - Dein kostenloser Angebotsvergleich",
    [EmailTemplates[EmailTemplate.AUTOMATED_EMAIL_CONSULTATION_TAB_MEETING_COMPLETION]]:
        "Automated Email: CONSULTATION_TAB_MEETING_COMPLETION",
    [EmailTemplates[EmailTemplate.AUTOMATED_EMAIL_MISSING_DOCUMENTS]]:
        "Automated Email: AUTOMATED_EMAIL_MISSING_DOCUMENTS",
    [EmailTemplates[EmailTemplate.AUTOMATED_EMAIL_UPLOAD_DOCUMENTS]]:
        "Automated Email: AUTOMATED_EMAIL_UPLOAD_DOCUMENTS",
    [EmailTemplates[EmailTemplate.FINLINE_SUBMISSION]]: "Automated Email: FINLINE_SUBMISSION",
} as const;

export const FROM_EMAIL_SUFFIX = "& dein miracl Team";

export type EmailTemplateDataKeys = EmailTemplateData[EmailTemplate.SURVEY_SUBMIT_TIPSTER_CUSTOMER] &
    EmailTemplateData[EmailTemplate.DEAL_FORM_SUBMIT_BY_TIPSTER] &
    EmailTemplateData[EmailTemplate.SURVEY_SUBMIT_COMMERCIAL_OR_OTHER] &
    EmailTemplateData[EmailTemplate.SURVEY_SUBMIT_APPOINTMENT_BOOKED] &
    EmailTemplateData[EmailTemplate.SURVEY_SUBMIT_HIGH_INTENT] &
    EmailTemplateData[EmailTemplate.SURVEY_SUBMIT_HIGH_INTENT_NO_PHONE_NUMBER] &
    EmailTemplateData[EmailTemplate.AUTOMATED_EMAIL_CUSTOMER_REACHED_COLDCALL] &
    EmailTemplateData[EmailTemplate.AUTOMATED_EMAIL_CUSTOMER_NOT_REACHED_COLDCALL] &
    EmailTemplateData[EmailTemplate.AUTOMATED_EMAIL_CUSTOMER_NOT_REACHED_OTHER] &
    EmailTemplateData[EmailTemplate.AUTOMATED_EMAIL_CUSTOMER_NOT_REACHED_IMMOSCOUT] &
    EmailTemplateData[EmailTemplate.AUTOMATED_EMAIL_CUSTOMER_NOT_REACHED_REAL_ESTATE_AGENT] &
    EmailTemplateData[EmailTemplate.WAITING_FOR_OFFER] &
    EmailTemplateData[EmailTemplate.CONTACT_DATA] &
    EmailTemplateData[EmailTemplate.MAGIC_LINK] &
    EmailTemplateData[EmailTemplate.NEXT_STEPS_NOT_REACHED] &
    EmailTemplateData[EmailTemplate.DATE_PROPOSED] &
    EmailTemplateData[EmailTemplate.WAITING_FOR_SOMETHING_IN_GENERAL] &
    EmailTemplateData[EmailTemplate.SIGHTSEEING] &
    EmailTemplateData[EmailTemplate.FOLLOW_UP] &
    EmailTemplateData[EmailTemplate.ARCHIVE_COMPETITION] &
    EmailTemplateData[EmailTemplate.ARCHIVE_NOT_REACHED] &
    EmailTemplateData[EmailTemplate.ARCHIVE_NO_ANSWER] &
    EmailTemplateData[EmailTemplate.ARCHIVE_WRONG_NUMBER] &
    EmailTemplateData[EmailTemplate.SECOND_EMAIL_WAITING_FOR_OFFER] &
    EmailTemplateData[EmailTemplate.SECOND_EMAIL_DATE_PROPOSED] &
    EmailTemplateData[EmailTemplate.SECOND_EMAIL_WAITING_FOR_SOMETHING_IN_GENERAL] &
    EmailTemplateData[EmailTemplate.SECOND_EMAIL_SIGHTSEEING] &
    EmailTemplateData[EmailTemplate.SECOND_EMAIL_FOLLOW_UP] &
    EmailTemplateData[EmailTemplate.THIRD_EMAIL] &
    EmailTemplateData[EmailTemplate.AUTOMATED_EMAIL_OFFER_COMPARISON] &
    EmailTemplateData[EmailTemplate.AUTOMATED_EMAIL_CONSULTATION_TAB_MEETING_COMPLETION] &
    EmailTemplateData[EmailTemplate.AUTOMATED_EMAIL_MISSING_DOCUMENTS] &
    EmailTemplateData[EmailTemplate.AUTOMATED_EMAIL_UPLOAD_DOCUMENTS] &
    EmailTemplateData[EmailTemplate.FINLINE_SUBMISSION];

export type EmailTemplateData = {
    [EmailTemplate.SURVEY_SUBMIT_TIPSTER_CUSTOMER]: {
        firstName: string;
        realEstateAgentName: string;
        bookAppointmentLink: string;
    };
    [EmailTemplate.DEAL_FORM_SUBMIT_BY_TIPSTER]: {
        fullCustomerName: string;
        realEstateAgentName: string;
    };
    [EmailTemplate.SURVEY_SUBMIT_COMMERCIAL_OR_OTHER]: {
        firstName: string;
    };
    [EmailTemplate.SURVEY_SUBMIT_APPOINTMENT_BOOKED]: {
        mortgagingToolLoginLink?: string;
        bookAppointmentLink?: string;
        additionalInfoLink?: string;
        firstName: string;
    };
    [EmailTemplate.SURVEY_SUBMIT_HIGH_INTENT]: {
        mortgagingToolLoginLink?: string;
        bookAppointmentLink?: string;
        additionalInfoLink?: string;
        firstName: string;
    };
    [EmailTemplate.SURVEY_SUBMIT_HIGH_INTENT_NO_PHONE_NUMBER]: {
        mortgagingToolLoginLink?: string;
        bookAppointmentLink?: string;
        additionalInfoLink?: string;
        firstName: string;
        topConditionText: string;
    };
    [EmailTemplate.AUTOMATED_EMAIL_CUSTOMER_REACHED_COLDCALL]: {
        lastName: string;
        specialistName: string;
        specialistEmail: string;
        specialistPhoneNumber: string;
        specialistTradeLicenseNumber?: string;
        specialistAdvisorId?: string;
        salutation: string;
        bookAppointmentLink: string;
    };
    [EmailTemplate.AUTOMATED_EMAIL_CUSTOMER_NOT_REACHED_COLDCALL]: {
        lastName: string;
        specialistName: string;
        specialistEmail: string;
        specialistPhoneNumber: string;
        specialistTradeLicenseNumber?: string;
        specialistAdvisorId?: string;
        salutation: string;
        bookAppointmentLink: string;
        propertyAddress: string;
    };
    [EmailTemplate.AUTOMATED_EMAIL_CUSTOMER_NOT_REACHED_OTHER]: {
        firstName: string;
        specialistName: string;
        specialistEmail: string;
        specialistPhoneNumber: string;
        specialistTradeLicenseNumber?: string;
        specialistAdvisorId?: string;
    };
    [EmailTemplate.AUTOMATED_EMAIL_CUSTOMER_NOT_REACHED_IMMOSCOUT]: {
        firstName: string;
        specialistName: string;
        specialistEmail: string;
        specialistPhoneNumber: string;
        specialistTradeLicenseNumber?: string;
        specialistAdvisorId?: string;
    };
    [EmailTemplate.AUTOMATED_EMAIL_CUSTOMER_NOT_REACHED_REAL_ESTATE_AGENT]: {
        firstName: string;
        specialistName: string;
        realEstateAgentName: string;
        specialistEmail: string;
        specialistPhoneNumber: string;
        specialistTradeLicenseNumber?: string;
        specialistAdvisorId?: string;
    };
    [EmailTemplate.WAITING_FOR_OFFER]: {
        firstName: string;
        specialistName: string;
        specialistEmail: string;
        specialistPhoneNumber: string;
        specialistTradeLicenseNumber?: string;
        specialistAdvisorId?: string;
    };
    [EmailTemplate.CONTACT_DATA]: {
        firstName: string;
        specialistName: string;
        specialistEmail: string;
        specialistPhoneNumber: string;
        specialistTradeLicenseNumber?: string;
        specialistAdvisorId?: string;
    };
    [EmailTemplate.MAGIC_LINK]: {
        firstName: string;
        specialistName: string;
        specialistEmail: string;
        specialistPhoneNumber: string;
        hubMagicLink: string;
        specialistTradeLicenseNumber?: string;
        specialistAdvisorId?: string;
    };
    [EmailTemplate.NEXT_STEPS_NOT_REACHED]: {
        firstName: string;
        specialistName: string;
        specialistEmail: string;
        specialistPhoneNumber: string;
        specialistTradeLicenseNumber?: string;
        specialistAdvisorId?: string;
    };
    [EmailTemplate.DATE_PROPOSED]: {
        firstName: string;
        specialistName: string;
        specialistEmail: string;
        specialistPhoneNumber: string;
        specialistTradeLicenseNumber?: string;
        specialistAdvisorId?: string;
    };
    [EmailTemplate.WAITING_FOR_SOMETHING_IN_GENERAL]: {
        firstName: string;
        specialistName: string;
        specialistEmail: string;
        specialistPhoneNumber: string;
        specialistTradeLicenseNumber?: string;
        specialistAdvisorId?: string;
    };
    [EmailTemplate.SIGHTSEEING]: {
        firstName: string;
        specialistName: string;
        specialistEmail: string;
        specialistPhoneNumber: string;
        specialistTradeLicenseNumber?: string;
        specialistAdvisorId?: string;
    };
    [EmailTemplate.FOLLOW_UP]: {
        firstName: string;
        specialistName: string;
        specialistEmail: string;
        specialistPhoneNumber: string;
        specialistTradeLicenseNumber?: string;
        specialistAdvisorId?: string;
    };
    [EmailTemplate.ARCHIVE_COMPETITION]: {
        firstName: string;
        specialistName: string;
        specialistEmail: string;
        specialistPhoneNumber: string;
        specialistTradeLicenseNumber?: string;
        specialistAdvisorId?: string;
    };
    [EmailTemplate.ARCHIVE_NOT_REACHED]: {
        firstName: string;
        specialistName: string;
        specialistEmail: string;
        specialistPhoneNumber: string;
        specialistTradeLicenseNumber?: string;
        specialistAdvisorId?: string;
    };
    [EmailTemplate.ARCHIVE_NO_ANSWER]: {
        firstName: string;
        specialistName: string;
        specialistEmail: string;
        specialistPhoneNumber: string;
        specialistTradeLicenseNumber?: string;
        specialistAdvisorId?: string;
    };
    [EmailTemplate.ARCHIVE_WRONG_NUMBER]: {
        firstName: string;
        specialistName: string;
        specialistEmail: string;
        specialistPhoneNumber: string;
        specialistTradeLicenseNumber?: string;
        specialistAdvisorId?: string;
    };
    [EmailTemplate.SECOND_EMAIL_WAITING_FOR_OFFER]: {
        firstName: string;
        specialistName: string;
        specialistEmail: string;
        specialistPhoneNumber: string;
        specialistTradeLicenseNumber?: string;
        specialistAdvisorId?: string;
    };
    [EmailTemplate.SECOND_EMAIL_DATE_PROPOSED]: {
        firstName: string;
        specialistName: string;
        specialistEmail: string;
        specialistPhoneNumber: string;
        specialistTradeLicenseNumber?: string;
        specialistAdvisorId?: string;
    };
    [EmailTemplate.SECOND_EMAIL_WAITING_FOR_SOMETHING_IN_GENERAL]: {
        firstName: string;
        specialistName: string;
        specialistEmail: string;
        specialistPhoneNumber: string;
        specialistTradeLicenseNumber?: string;
        specialistAdvisorId?: string;
    };
    [EmailTemplate.SECOND_EMAIL_SIGHTSEEING]: {
        firstName: string;
        specialistName: string;
        specialistEmail: string;
        specialistPhoneNumber: string;
        specialistTradeLicenseNumber?: string;
        specialistAdvisorId?: string;
    };
    [EmailTemplate.SECOND_EMAIL_FOLLOW_UP]: {
        firstName: string;
        specialistName: string;
        specialistEmail: string;
        specialistPhoneNumber: string;
        specialistTradeLicenseNumber?: string;
        specialistAdvisorId?: string;
    };
    [EmailTemplate.THIRD_EMAIL]: {
        firstName: string;
        specialistName: string;
        specialistEmail: string;
        specialistPhoneNumber: string;
        specialistTradeLicenseNumber?: string;
        specialistAdvisorId?: string;
    };
    [EmailTemplate.AUTOMATED_EMAIL_OFFER_COMPARISON]: {
        firstName: string;
    };
    [EmailTemplate.AUTOMATED_EMAIL_CONSULTATION_TAB_MEETING_COMPLETION]: {
        firstName: string;
        specialistName: string;
        specialistEmail: string;
        specialistPhoneNumber: string;
        hubMagicLink: string;
        specialistTradeLicenseNumber?: string;
        specialistAdvisorId?: string;
    };
    [EmailTemplate.AUTOMATED_EMAIL_MISSING_DOCUMENTS]: {
        firstName: string;
        specialistName: string;
        specialistEmail: string;
        specialistPhoneNumber: string;
        hubMagicLink: string;
        missingDocuments: string;
        specialistTradeLicenseNumber?: string;
        specialistAdvisorId?: string;
    };
    [EmailTemplate.AUTOMATED_EMAIL_UPLOAD_DOCUMENTS]: {
        firstName: string;
        specialistName: string;
        specialistEmail: string;
        specialistPhoneNumber: string;
        hubMagicLink: string;
        missingDocuments: string;
        specialistTradeLicenseNumber?: string;
        specialistAdvisorId?: string;
    };
    [EmailTemplate.FINLINE_SUBMISSION]: {
        firstName: string;
        specialistName: string;
        specialistEmail: string;
        specialistPhoneNumber: string;
        dealLink: string;
        bankName: string;
        runtime: string;
        interestRate: string;
    };
};

export type SendGridEmailValidationResponse = {
    statusCode: number;
    body: {
        result: {
            verdict: "Valid" | "Risky" | "Invalid";
            checks: {
                additional: {
                    has_known_bounces: boolean;
                };
            };
        };
    };
};

export type SendgridSendEmailResponse = {
    body: string;
    subject: string;
};

export const CLOSED_LOST_REASON_TO_NEXT_STEPS_TO_EMAIL_TEMPLATE_MAP: Partial<
    Record<ClosedLostReasonSingleValues, EmailTemplate>
> = {
    [ClosedLostReasonSingleValues.NOT_REACHED]: EmailTemplate.ARCHIVE_NOT_REACHED,
    [ClosedLostReasonSingleValues.NO_ANSWER]: EmailTemplate.ARCHIVE_NO_ANSWER,
    [ClosedLostReasonSingleValues.WRONG_NUMBER]: EmailTemplate.ARCHIVE_WRONG_NUMBER,
    [ClosedLostReasonSingleValues.COMPETITION]: EmailTemplate.ARCHIVE_COMPETITION,
};

export const DEAL_NEXT_STEPS_TO_EMAIL_TEMPLATE_MAP: Record<DealNextStep, EmailTemplate | undefined> = {
    [DealNextStep.WAITING_FOR_OFFER]: EmailTemplate.WAITING_FOR_OFFER,
    [DealNextStep.CONTACT_DATA]: EmailTemplate.CONTACT_DATA,
    [DealNextStep.MAGIC_LINK]: EmailTemplate.MAGIC_LINK,
    [DealNextStep.CONTRACT]: undefined,
    [DealNextStep.CONSULTATION_PROTOCOL]: undefined,
    [DealNextStep.DATE_PROPOSED]: EmailTemplate.DATE_PROPOSED,
    [DealNextStep.WAITING_FOR_SOMETHING_IN_GENERAL]: EmailTemplate.WAITING_FOR_SOMETHING_IN_GENERAL,
    [DealNextStep.SIGHTSEEING]: EmailTemplate.SIGHTSEEING,
    [DealNextStep.NOT_REACHED]: EmailTemplate.NEXT_STEPS_NOT_REACHED,
    [DealNextStep.FOLLOW_UP]: EmailTemplate.FOLLOW_UP,
    [DealNextStep.OTHER]: undefined,
    [DealNextStep.ARCHIVE]: EmailTemplate.ARCHIVE_COMPETITION,
};

export const DEAL_NEXT_STEPS_TO_SECOND_EMAIL_TEMPLATE_MAP: Record<DealNextStep, EmailTemplate | undefined> = {
    [DealNextStep.WAITING_FOR_OFFER]: EmailTemplate.SECOND_EMAIL_WAITING_FOR_OFFER,
    [DealNextStep.CONTACT_DATA]: undefined,
    [DealNextStep.MAGIC_LINK]: undefined,
    [DealNextStep.CONTRACT]: undefined,
    [DealNextStep.CONSULTATION_PROTOCOL]: undefined,
    [DealNextStep.DATE_PROPOSED]: EmailTemplate.SECOND_EMAIL_DATE_PROPOSED,
    [DealNextStep.WAITING_FOR_SOMETHING_IN_GENERAL]: EmailTemplate.SECOND_EMAIL_WAITING_FOR_SOMETHING_IN_GENERAL,
    [DealNextStep.SIGHTSEEING]: EmailTemplate.SECOND_EMAIL_SIGHTSEEING,
    [DealNextStep.NOT_REACHED]: undefined,
    [DealNextStep.OTHER]: undefined,
    [DealNextStep.ARCHIVE]: undefined,
    [DealNextStep.FOLLOW_UP]: EmailTemplate.SECOND_EMAIL_FOLLOW_UP,
};

export const CLOSED_LOST_REASONS_WITH_EMAILS: ClosedLostReasonSingleValues[] = Object.keys(
    CLOSED_LOST_REASON_TO_NEXT_STEPS_TO_EMAIL_TEMPLATE_MAP,
) as ClosedLostReasonSingleValues[];

export const emailTemplatesVariableData: EmailTemplateDataKeys = {
    additionalInfoLink: "https://www.example.com/",
    bookAppointmentLink: "https://www.example.com/",
    hubMagicLink: "https://www.example.com/",
    missingDocuments: "<ul><li>Example<ul><li>sub-example</li></ul></li></ul>",
    specialistEmail: "someSpecialist@domain.com",
    specialistName: "Max Mustermann",
    specialistPhoneNumber: "+4311111111",
    firstName: "John",
    lastName: "Smith",
    fullCustomerName: "John Smith",
    propertyAddress: "Adresse",
    realEstateAgentName: "Tippster Name",
    salutation: "Herr",
    topConditionText: "Top Konditionen",
    mortgagingToolLoginLink: "https://www.example.com/",
    bankName: "Bank Austria",
    dealLink: "https://app.finline.at/submission?dealId=${dealId}",
    interestRate: "3.50%",
    runtime: "10/30",
    specialistTradeLicenseNumber: "TLN",
    specialistAdvisorId: "Max-advisorId",
};

export const NON_EDITABLE_EMAIL_TEMPLATES: EmailTemplate[] = [
    EmailTemplate.SURVEY_SUBMIT_APPOINTMENT_BOOKED,
    EmailTemplate.AUTOMATED_EMAIL_OFFER_COMPARISON,
    EmailTemplate.SURVEY_SUBMIT_TIPSTER_CUSTOMER,
    EmailTemplate.DEAL_FORM_SUBMIT_BY_TIPSTER,
    EmailTemplate.SURVEY_SUBMIT_HIGH_INTENT_NO_PHONE_NUMBER,
    EmailTemplate.SURVEY_SUBMIT_HIGH_INTENT,
    EmailTemplate.SURVEY_SUBMIT_COMMERCIAL_OR_OTHER,
    EmailTemplate.AUTOMATED_EMAIL_CONSULTATION_TAB_MEETING_COMPLETION,
];

export const FINLINE_SUBMISSION_EMAIL = "backoffice@finline.at";
