import * as Yup from "yup";
import { Shape } from "../types";
import { numberValidation, stringOptionValidation, stringValidation } from "../util";
import { TRUE_FALSE_VALUES } from "../../../constants";
import { DealProperties } from "../../../models";

export const financingRequestsSchema = Yup.object<Shape<Partial<DealProperties>>>({
    amortizationStartDate: numberValidation(),
    payoutDate: numberValidation(),
    fixedRateRuntime: numberValidation(0),
    totalRuntime: numberValidation(0),
    bankGuarantee: stringOptionValidation(TRUE_FALSE_VALUES, true),
    bankGuaranteeDuration: stringValidation(),
    bankGuaranteeAmount: stringValidation(),
});
