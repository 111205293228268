import * as Yup from "yup";
import { Shape } from "../types";
import { multiSelectValidation, numberValidation, stringOptionValidation, stringValidation } from "../util";
import {
    PROPERTY_TYPE_VALUES,
    PROPERTY_FINANCING_TYPE_VALUES,
    PROPERTY_EXTERIOR_SURFACES,
    JA_NEIN_VALUES,
    PROPERTY_BASEMENT_VALUES,
    PROPERTY_WINDOW_TYPES,
    PROPERTY_OBJECT_PURPOSE_VALUES,
} from "../../../constants";
import { DealProperties } from "../../../models";

export const financingPropertySchema = Yup.object<Shape<Partial<DealProperties>>>({
    propertyType: stringOptionValidation(PROPERTY_TYPE_VALUES, true),
    propertyFinancingType: stringOptionValidation(PROPERTY_FINANCING_TYPE_VALUES, true),
    propertyCountry: stringValidation(),
    propertyState: stringValidation(),
    propertyZipCode: stringValidation(),
    propertyCity: stringValidation(),
    propertyAddress: stringValidation(),
    propertyIdentificationEntryNumber: numberValidation(),
    propertyIdentificationMunicipality: stringValidation(),
    propertyConstructionYear: numberValidation(),
    propertyLastRenovatedYear: numberValidation(),
    propertyLivingSpaceArea: numberValidation(0),
    propertyLandArea: numberValidation(0),
    propertyExteriorSurfaces: multiSelectValidation(PROPERTY_EXTERIOR_SURFACES),
    propertyBalconyArea: numberValidation(0),
    propertyRoofTerraceArea: numberValidation(0),
    propertyGardenArea: numberValidation(0),
    propertyLoggiaArea: numberValidation(0),
    propertyTerraceArea: numberValidation(0),
    propertyWinterGardenArea: numberValidation(0),
    propertyParkingSpots: numberValidation(0),
    propertyHasElevator: stringOptionValidation(JA_NEIN_VALUES, true),
    propertyBasementType: stringOptionValidation(PROPERTY_BASEMENT_VALUES, true),
    propertyFloors: numberValidation(0),
    propertyFloorLocation: numberValidation(0),
    propertyWindowType: stringOptionValidation(PROPERTY_WINDOW_TYPES, true),
    // propertyOwner: stringOptionValidation(PROPERTY_OWNER_VALUES),
    propertyObjectPurpose: stringOptionValidation(PROPERTY_OBJECT_PURPOSE_VALUES, true),
    propertyCommercialUse: numberValidation(),
});
