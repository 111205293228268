import * as Yup from "yup";
import { Shape } from "../types";
import { stringOptionValidation, stringValidation } from "../util";
import { REFERRAL_VALUES } from "../../../constants";
import { DealProperties } from "../../../models";

export const referralSchema = Yup.object<Shape<Partial<DealProperties>>>({
    referral: stringOptionValidation(REFERRAL_VALUES, true),
    referralSourceName: stringValidation(),
});
