export enum DealPropertiesBankSection {
    PROPERTY = "property",
    PERSONAL_BORROWER_1 = "personal_borrower_1",
    PERSONAL_BORROWER_2 = "personal_borrower_2",
}

export enum SalariesPerYearValues {
    SALARIES_12 = 12,
    SALARIES_14 = 14,
}

export enum DealPropertiesSectionId {
    CUSTOMER_REACHED = "customerReached",
    GENERAL_INFORMATION = "generalInformation",
    PROJECT_COSTS = "projectCosts",
    ESTIMATION_DATA = "estimationData",
    ESTIMATION = "estimation",
    LTV = "ltv",
    EXISTING_OBJECTS = "existingObjects",
    INCOME_AND_LIABILITIES = "incomeAndLiabilities",
    INCOME = "income",
    LIABILITIES = "liabilities",
    DSTI = "dsti",
    PERSONAL_INFORMATION = "personalInformation",
    HOUSEHOLD_DATA = "householdData",
    FINANCING_REQUESTS = "financingRequests",
    NEXT_STEPS = "nextSteps",

    LAND_PLOT = "landPlot",
    PROPERTY = "property",
    CONSTRUCTION = "construction",

    SURVEY_INFORMATION = "surveyInformation",
    COMPETITION_DATA = "competitionData",
    FINANCING_OBJECT = "financingObject",

    INTERIM_FINANCING = "interimFinancing",

    REFERRAL = "referral",
    REPAYMENT_PLAN = "repaymentPlan",
    FINANCING_COSTS = "financingCosts",
    FUNDING_DETAILS = "fundingDetails",
    OPINION = "opinion",
}
